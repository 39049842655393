<template>
  <div class="brands">

    <NavHeader />
    <div class="content_container ">
      <div class="banner">

        <img
          class="banner"
          v-for='(item,index) in banner'
          :key='index'
          :src="$config.baseUrl+item.get_files.files_path"
          alt=""
        /> </div>
      <div class="shop-list">

        <ul>
          <li
            class="item"
            v-for="(item, index) in shopList"
            :key="index"
          >
            <div
              class="card"
              :style="{background:'url('+$config.baseUrl+item.shop_ad_img+')'}"
              @click="$util.toShop(item.shop_type, item.shop_id )"
            >

              <!-- <div class="tips"><span v-if='item.shop_type==2'>品牌供应商</span><span v-if="item.shop_type==3">4s旗舰店</span></div> -->
              <div class="bg">

              </div>
              <div class="c">
                <div>

                  <img
                    v-if='item.shop_logo'
                    :src=" item.shop_logo"
                  />
                  <img
                    v-else
                    src="../../assets/images/tx.jpg"
                    alt=""
                  />
                  <p class="p1">{{item.shop_name}}</p>
                </div>
              </div>
            </div>
            <dl
              v-if='!item.get_goods.length'
              style='height:160px;'
            >
              <el-empty
                image-size='110'
                style='padding:0'
                description="暂无商品"
              ></el-empty>
            </dl>
            <dl v-else>
              <dd
                v-for='(o,i) in item.get_goods'
                :key="i"
                @click="$util.toDetail(o.shop_type, o.goods_id )"
              >
                <img :src='$config.baseUrl+o.good_url'>

                <div
                  class="item-price"
                  v-if="o.isprice == 2"
                >
                  <span>￥{{ parseFloat(o.min_price).toFixed(2) }}</span>
                  -
                  <span>￥{{ parseFloat(o.max_price).toFixed(2) }}</span>
                </div>

                <div
                  class="item-price"
                  v-if="o.isonly == 1 && o.isprice == 1"
                >

                  <span>￥{{
                        parseFloat(o.sales_price/ 100).toFixed(2) 
                      }}</span>
                </div>
                <div
                  class="item-price"
                  v-if="o.isonly == 2 && o.isprice == 1"
                >
                  <span>￥{{ o.spec_min_price}}
                    起</span>
                </div>

              </dd>
            </dl>
          </li>
        </ul>
      </div>
      <el-pagination
        @current-change="currentChange"
        :current-page.sync="searchData.page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size.sync="searchData.limit"
        :total="total"
      >
      </el-pagination>
    </div>

  </div>
</template>
<script>
import NavHeader from "@/components/layout/nav-header";

export default {
  data() {
    return {
      shop: [],
      banner: [],
      total: 0,
      shopList: [],
      searchData: {
        brand_id: "",
        catid: "",
        shop_type: 3,
        page: 1,
        limit: 10
      }
    };
  },
  computed: {},
  mounted() {
    this.currentChange();
    this.getBanner();
  },
  methods: {
    getBanner() {
      this.$get("home/ad", { pid: 540 }).then(res => {
        this.banner = res || [];
      });
    },
    getProduct(index) {
      if (index == 0) {
        this.searchData.price_pr = undefined;
        this.searchData.sale_num_pr = undefined;
      }
      if (index == 1) {
        this.searchData.price_pr = undefined;
        this.searchData.sale_num_pr =
          this.searchData.sale_num_pr == "desc" ? "asc" : "desc";
      }
      if (index == 2) {
        this.searchData.sale_num_pr = undefined;
        this.searchData.price_pr =
          this.searchData.price_pr == "asc" ? "desc" : "asc";
      }

      this.currentChange();
    },

    currentChange() {
      this.$get("/home/shop", this.searchData).then(res => {
        console.log("res.items.data", res.items.data);

        res.items.data.forEach(item => {
          item.get_goods = item.get_goods.filter((o, i) => {
            return i < 4;
          });
        });
        this.shopList = res.items.data;
        this.total = res.items.total;
      });
    }
  },
  components: {
    NavHeader
  },
  created() {}
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/mixins";

.banner {
  width: 100%;
  height: 350px;
  img {
    width: 100%;
    height: 100%;
  }
}
.shop-list {
  width: 100%;
  background: #fff;

  ul {
    width: 100%;
    box-sizing: border-box;
    li {
      // background: #f0f0f0;
      border: 1px solid #f0f0f0;
      margin-top: 10px;
      box-sizing: border-box;

      @include space-between;
      width: 100%;
      padding: 20px;
      .card {
        overflow: hidden;
        background-repeat: no-repeat !important;
        background-size: 100% 100% !important;
        position: relative;
        .bg {
          position: absolute;
          z-index: 1;
          width: 100%;
          height: 100%;

          // -webkit-filter: blur(4);
          // filter: blur(4px);
          background: rgba($color: #000000, $alpha: 0.5);
        }

        @include center;

        box-sizing: border-box;
        border: 1px solid #f0f0f0;
        width: 480px;
        height: 190px;
        margin-right: 10px;
        position: relative;
        .tips {
          position: absolute;
          right: 10px;
          top: 10px;
          border: 1px solid #f8be7c;
          // background: rgba($color: #f2952b, $alpha: 0.3);
          border-radius: 10px;
          color: #e94c15;
          padding: 2px 5px;
        }
        img {
          margin-right: 10px;
          width: 135px;
          height: 50px;
        }
        .c {
          width: 300px;
          height: 150px;
          margin: 0;
          @include center;

          background: rgba($color: #fff, $alpha: 0.2);
          padding: 10px 30px;

          position: absolute;
          z-index: 2;
          text-align: center;

          .p1 {
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 10px;
            margin-top: 10px;
          }
          p {
            text-align: center;

            color: #fff;
          }
          .btn {
            width: 120px;
            text-align: center;
            color: #fff;
            height: 35px;
            line-height: 35px;

            border-radius: 20px;
            border: 0;
            background-color: #f32f01; /* 浏览器不支持时显示 */
            background-image: linear-gradient(#f32f01, #f39801);
            margin-top: 10px;
          }
        }
      }

      dl {
        flex: 1;
        margin: 0 -5px;
         dd {
          box-sizing: border-box;
          background: #fff;
          text-align: center;
          float: left;
          width: 140px;
          margin: 0 15px;
          height: 190px;
          word-break: break-all;
          line-height: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          img {
            width: 140px;
            height: 140px;
          }
          .item-price {
            width: 100%;
            padding: 10px 0;
            text-align: left;
            span {
              font-size: 14px;
              color: red;
              font-weight: bold;
              text-align: left;
            }
          }
        }
      }
      dl:after {
        content: "";
        width: 30%;
      }
    }
  }
}
</style>
 